const AUTH_PHRASE =
  'Welcome! To ensure your security, please sign this message to authenticate your account. Thank you for using our service!'

const SERVER_URL = 'https://api2.infrax.network'
// const SERVER_URL = 'http://127.0.0.1:8420';

const AUTH_URL = `${SERVER_URL}/auth`

const JWT_STORE_KEY = 'jwt'

const LIMIT = 25

export { AUTH_PHRASE, SERVER_URL, AUTH_URL, JWT_STORE_KEY, LIMIT }
