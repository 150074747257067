<template>
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Telegram</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.26989 14.7621C12.3262 12.098 16.3649 10.3421 18.3855 9.49356C24.1547 7.07112 25.3538 6.64997 26.1349 6.63612C26.307 6.63319 26.6909 6.67597 26.9402 6.87984C27.1504 7.05182 27.2082 7.28463 27.236 7.4478C27.2639 7.61097 27.2983 7.98304 27.2709 8.27332C26.9585 11.5896 25.6056 19.6376 24.9171 23.352C24.6258 24.9238 24.0525 25.4506 23.4974 25.5022C22.2907 25.6142 21.375 24.6973 20.2062 23.9242C18.3776 22.714 17.3446 21.9611 15.57 20.7802C13.5187 19.4157 14.8487 18.6657 16.0175 17.44C16.3233 17.1191 21.6392 12.2382 21.7419 11.7952C21.7547 11.7398 21.7668 11.5334 21.6451 11.4244C21.5233 11.3153 21.3442 11.3526 21.215 11.3824C21.0318 11.4244 18.1108 13.3737 12.4521 17.2298C11.6232 17.8045 10.872 18.0847 10.1993 18.07C9.45761 18.0537 8.03078 17.6468 6.97001 17.2986C5.66825 16.8716 4.63407 16.6459 4.72423 15.9211C4.77118 15.5436 5.2864 15.1572 6.26989 14.7625V14.7621Z"
      fill="currentColor"
    />
  </svg>
</template>
